<template>
  <div>
    <div>
      <h2>Search and add a pin</h2>
      <label>
        <gmap-autocomplete @place_changed="setPlace"> </gmap-autocomplete>
        <button @click="addMarker">Add</button>
      </label>
      <br />
    </div>
    <br />
    <gmap-map
      :center="center"
      :zoom="12"
      ref="mapRef"
      style="width: 100%; height: 400px"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="panTo(m.position)"
        :clickable="true"
        :draggable="true"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: 'googlemap',
  data() {
    return {
      // default to Bangkok to keep it simple
      // change this to whatever makes sense
      center: { lat: 13.772188, lng: 100.480889 },
      markers: [],
      places: [],
      currentPlace: null,
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    // receives a place object via the autocomplete component
    panTo(position) {
      // console.log(marker);
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo(position);
      });
    },
    setPlace(place) {
      console.log('set place');
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      } else {
        this.$refs.mapRef.$mapPromise.then((map) => {
          const center = map.getCenter();
          this.markers.push({
            position: { lat: center.lat(), lng: center.lng() },
          });
        });
        // console.log(map.getCenter());
      }
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>

<style scoped></style>
